@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

:root {
    --primary: #6948ff;
    --secondary: #553bcb;
    --darkgrey: #22262a;
    --lightgrey: #d3d3d3;
    --grey: #808080;
    --white: #f8f8f8;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: var(--white);
}

/* Apply overflow: hidden only when the homepage class is present */
body.homepage {
    overflow: hidden;
}

.dynamic-height-iframe {
    height: 100vh; /* Full viewport height */
    width: 100%;
    border: none;
}