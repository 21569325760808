.floating-text {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    text-align: center;
}

.floating-text a {
    /* color: var(--primary); */
    text-decoration: underline;
}

.floating-text a:hover {
    color: var(--primary);
    text-decoration: underline;
}

.mobile-text {
    display: none;
}

@media (max-width: 600px) {
    .desktop-text {
        display: none;
    }

    .mobile-text {
        display: inline;
        font-size: 12px;
        padding: 5px 10px;
        background-color: rgba(0, 0, 0, 1); /* Solid background for text */
        border-radius: 5px;
    }

    .floating-text {
        /* background-color: rgba(0, 0, 0, 0.0); /* 50% transparency */
        display: none;
    }
}