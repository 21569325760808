#FCPForm {
    width: 100%;
    height: "1900px";
    background: var(--darkgrey);
}

.tenant-registration {
    overflow-y: hidden;
    overflow-x: hidden;
}

.form-page {
    width: 100vw;
    height: 100vh;
    background: var(--darkgrey);
}

.return-button {
    width: 400px;
    padding: 8px 0;
    border-radius: 4px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
}

.return-button:hover {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
}