/* Style for the floating button */
.floating-button {
    position: fixed;
    top: 75px;
    right: 20px;
    background-color: var(--primary);
    color: var(--white);
    width: 50px; /* Set the width */
    height: 50px; /* Set the height */
    display: flex; /* Center the icon */
    align-items: center; /* Center the icon */
    justify-content: center; /* Center the icon */
    border-radius: 50%; /* Make it round */
    cursor: pointer;
    z-index: 1000; /* Ensure it is above the iframe */
}

/* Style for the menu */
.nav-menu {
    position: fixed;
    top: 120px; /* Adjust based on the floating button position */
    right: 20px;
    background-color: var(--secondary);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000; /* Ensure it is above the iframe */
    padding: 10px;
    color: var(--white); /* Explicitly set the text color */
}

.nav-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    padding: 10px;
    cursor: pointer;
    color: #333;
}

.nav-item:hover {
    background-color: var(--primary);
}

.nav-menu li {
    color: var(--white);
}