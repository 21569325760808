.hero {
    width: 100%;
    height: 100vh;
    background: var(--darkgrey);
    color: var(--white);
}

.container {
    max-width: 1240px;
    padding: 0 1rem;
    margin: auto;
}

.hero .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.col-1 {
    padding: 1rem 1rem 1rem 0;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col-1 h1 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--white);
}

.col-1 p {
    font-size: 1rem;
    color: var(--white);
    line-height: 1.4;
    margin-top: 1.2rem;
}

.col-1 .used-by p {
    color: var(--lightgrey);
    font-size: .7rem;
    margin: 2rem 0 1rem 0;
}

.col-2 {
    padding: 1rem;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: .8rem;
}

.col-2 p {
    color: var(--grey);
}

.form-layout {
    width: 400px;
    margin: auto;
}

#hero-map {
    border-radius: 25px;
}

.form-container {
    padding: 1.5rem 2rem;
}

.sign-in-txt {
    margin-bottom: 10px;
}

.social-login span {
    background: var(--white);
    padding: 16px 45px 6px 45px;
    border: 1px solid var(--lightgrey);
    border-radius: 4px;
}

.col-2 .divider p {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--lightgrey);
    line-height: 0.1em;
    margin: 2rem 0 2rem;
}

.col-2 .divider p span {
    background: var(--white);
    padding: 0 10px;
}


form input {
    display: block;
    width: 100%;
    margin: 1.2rem 0;
    padding: .5rem;
    border: 1px solid var(--lightgrey);
    background: var(--white);
    border-radius: 4px;
}

.form-footer {
    border-top: 1px solid var(--lightgrey);
    background: var(--white);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.form-footer p {
    padding: 1.5rem 2rem;
}

.form-footer span {
    font-weight: 500;
}

button {
    width: 100%;
    padding: 8px 0;
    border-radius: 4px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;
}

.cta-button {
    width: 150px;
    margin-top: 15px;
}

button:hover {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
}


@media screen and (max-width:940px) {
    .hero {
        height: auto;
        width: 100%;
    }

    .hero .container {
        padding: 0 1rem;
    }

    .hero .content {
        grid-template-columns: 1fr;
    }

    .col-1 {
        height: 90vh;
        padding: .2rem;
        max-width: 100%;
    }

    .hero h1 {
        font-size: 2.5rem;
    }

    .hero .col-2 {
        display: none;
    }
}