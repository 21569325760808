.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: left; /* Align text to the left */
}

.modal-content ul {
    /* list-style-type: decimal; Use decimal for numbered list */
    padding-left: 20px; /* Add padding to align numbers */
}

.modal-content li {
    margin-bottom: 10px; /* Add some space between list items */
}